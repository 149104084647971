import React, { useContext } from "react";

import firebase from 'gatsby-plugin-firebase'
import { ProfileContext } from "../../context/profile_data";
import { navigate } from "gatsby";

import { LocalStorage, LocalStorageKeys } from '../../utils'
import { ZendeskContext } from "../../context/context";

export function useCreateProfileDocument() {
    // const firestore = firebase.firestore()

    const { setProfile } = useContext(ProfileContext)
    const { setId } = useContext(ZendeskContext)

    async function createProfileDocument(user) {
        if (!user) return
        const userRef = firebase.firestore().doc(`users/${user.uid}`)
        const snapShot = await userRef.get()

        if (snapShot.exists) {
            const data = snapShot.data()
            if (data?.requester_Id) {
                setId(data.requester_Id)
            }

            if ((snapShot.data()?.firstName || snapShot.data()?.lastName)) {
                LocalStorage.set(LocalStorageKeys.USER_INFO, JSON.stringify(data))
                setProfile(data)
                navigate('/')
            } else {
                userRef.set({
                    email: user.email,
                    createdAt: user.metadata.creationTime
                })
                navigate('/profile')
            }
        } else {
            userRef.set({
                email: user.email,
                createdAt: user.metadata.creationTime
            })
            navigate('/profile')
        }
    }

    //     if (snapShot.exists && (snapShot.data()?.firstName || snapShot.data()?.lastName)) {
    //         const data = snapShot.data()
    //         LocalStorage.set(LocalStorageKeys.USER_INFO, JSON.stringify(data))
    //         setProfile(data)
    //         navigate('/')
    //     } else {
    //         userRef.set({
    //             email: user.email,
    //             createdAt: user.metadata.creationTime
    //         })
    //         navigate('/profile')
    //     }
    // }

    return createProfileDocument
}
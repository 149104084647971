import React, { useContext } from "react";
import firebase from 'gatsby-plugin-firebase'

export function useGetPerformance() {
    async function getPerformance(brand, market) {
        const snapshot = await firebase
            .firestore()
            .collection(`performance`)
            .doc(brand)
            .collection(market).get()

        const result = snapshot.docs.map(doc => doc.data()).filter((item) => Object.values(item).length > 0);
        return result
    }
    return getPerformance;
}
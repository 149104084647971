import React, { useRef, useEffect } from 'react';
import * as styles from './sliderbanner-modal.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper/core';
import 'swiper/swiper.min.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { RichText } from '../../../RichText/RichText'

SwiperCore.use([Autoplay]);
gsap.registerPlugin(ScrollTrigger);

const AnnouncementBar = ({ announcementsArray }) => {
    const announcements = announcementsArray;
    const barRef = useRef(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const bar = barRef.current;

        const showAnim = gsap.from(bar, {
            yPercent: -150,
            paused: true,
            duration: 0.4,
        }).progress(1);

        ScrollTrigger.create({
            trigger: bar,
            start: 'top top',
            end: 99999,
            onUpdate: (self) => {
                self.direction === -1 ? showAnim.play() : showAnim.reverse();
            },
        });
    }, []);


    return (
        <div className={styles.announcementBar} id='header-announcement' ref={barRef}>
            <Swiper
                autoplay={{
                    delay: 3000,
                    pauseOnMouseEnter: true,
                }}
                speed={1000}
                draggable={true}
                loop={true}
                className={styles.swiperContainer}
            >
                {announcements.map((announcement, index) => (
                    <SwiperSlide key={index} className={styles.swiperSlideItem}>
                        <div className={styles.slideItemContent}>
                            <RichText content={announcement.announcementText} />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default AnnouncementBar;

exports.components = {
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-ticket-js": () => import("./../../../src/pages/ticket.js" /* webpackChunkName: "component---src-pages-ticket-js" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-page-email-change-tsx": () => import("./../../../src/templates/PageEmailChange.tsx" /* webpackChunkName: "component---src-templates-page-email-change-tsx" */),
  "component---src-templates-page-helpdesk-tsx": () => import("./../../../src/templates/PageHelpdesk.tsx" /* webpackChunkName: "component---src-templates-page-helpdesk-tsx" */),
  "component---src-templates-page-login-tsx": () => import("./../../../src/templates/PageLogin.tsx" /* webpackChunkName: "component---src-templates-page-login-tsx" */),
  "component---src-templates-page-not-found-tsx": () => import("./../../../src/templates/PageNotFound.tsx" /* webpackChunkName: "component---src-templates-page-not-found-tsx" */),
  "component---src-templates-page-performance-tsx": () => import("./../../../src/templates/PagePerformance.tsx" /* webpackChunkName: "component---src-templates-page-performance-tsx" */),
  "component---src-templates-page-profile-tsx": () => import("./../../../src/templates/PageProfile.tsx" /* webpackChunkName: "component---src-templates-page-profile-tsx" */),
  "component---src-templates-page-silktide-tsx": () => import("./../../../src/templates/PageSilktide.tsx" /* webpackChunkName: "component---src-templates-page-silktide-tsx" */),
  "component---src-templates-template-tsx": () => import("./../../../src/templates/Template.tsx" /* webpackChunkName: "component---src-templates-template-tsx" */)
}


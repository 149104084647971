import React, { useContext, useEffect, useRef, useState } from "react"

import * as styles from './layout.module.scss'

import Footer from "./Footer/Footer"
import Header from "./Header/Header"
import "../../styles/main.scss"
import SideNav from "./SideNav/SideNav"
import { AuthContext } from "../../context/auth"
import { ProfileContext } from "../../context/profile_data"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import { LayoutContext, ZendeskContext } from "../../context/context"
import { useZendeskTickets } from "../../hooks"
import ZendeskQuickPanel from "../Zendesk/ZendeskQuickPanel/ZendeskQuickPanel"
import { StaticImage } from 'gatsby-plugin-image'
import Gradient from '../../images/gradientImages/page-gradient.png'
import HeaderDark from "../../images/gradientImages/header-dark.png"
import ProfileDetailPopup from "../ProfileDetailsPopup/ProfileDetailPopup"

const Layout = ({ children }) => {
    const { user } = useContext(AuthContext)
   const {visible, setVisible} = useContext(LayoutContext)
    const location = useLocation()
    const [display, setDisplay] = useState('none')

    const [offsetHeightFooter, setOffsetHeightFooter] = useState('0px')
    const [offsetHeightHeader, setOffsetHeightHeader] = useState('46px')

    const removeSlashes = (slug) => {
        let url = slug
        if (slug[0] === '/') url = slug.slice(1)
        if (slug[slug.length - 1] === '/') url = url.slice(0, -1)
        return url
    }

    useEffect(() => {
        if (!user && removeSlashes(location.pathname) !== 'sign-up' && removeSlashes(location.pathname) !== 'auth') navigate('/login')
        if (user) setDisplay('block')
    }, [user])

    const {
        id,
        counter,
        setTickets,
        setTicketLength,
        setActiveTickets
    } = useContext(ZendeskContext)
    const [zendeskTicket, { response, loading, error }] = useZendeskTickets()


    const {
        isDarkMode,
        setIsDarkMode,
    } = useContext(LayoutContext)


    const getZendeskTickets = async () => {
        await zendeskTicket({
            id: id
        })
    }

    useEffect(() => {
        if (!!id) {
            getZendeskTickets()
        }
    }, [id, counter])

    useEffect(() => {
        if (response?.tickets?.length > 0) {
            setTickets(response.tickets)
            const activeTickets = response
                .tickets
                .filter(({ status }) => status !== "closed")
            setActiveTickets(activeTickets)
            setTicketLength(activeTickets.length)
        }
    }, [response])

    

    // console.log("user", user);
    //Get Tickets and Active Ticket Numbers

    return (
        <div className={`page ${isDarkMode ? 'dark' : ''} `}>
            {
                (removeSlashes(location.pathname) === 'login' || removeSlashes(location.pathname) === 'sign-up' || removeSlashes(location.pathname) === 'search')
                    ? <>
                        <main>{children}</main>
                    </>
                    : <div className="page-main-content" style={{ display: display }}>
                        <Header />
                        <ZendeskQuickPanel />
                        <div className={`${styles.screenContent}`} >
                            <img
                                src={isDarkMode ? HeaderDark : Gradient}
                                alt=""
                                className={`gradient  ${styles.globalGradient}`}
                            />
                            <main className={`styles.main`}>{children}</main>
                        </div>
                        {visible && <ProfileDetailPopup setVisible ={setVisible}/>}
                        <Footer />
                    </div>
            }
        </div>
    )
}

export default Layout

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby'

export const useSearchQuery = () => {
    const data = useStaticQuery(
        graphql`
            query {
                allContentfulWodPageArticle {
                    nodes {
                        displayDate:createdAt(formatString: "DD MMM YYYY")
                        seoTitle
                        seoDescription {
                            seoDescription
                        }
                        pageTitle
                        slug
                        createdAt
                        #referenceOne {
                        #    sections {
                        #        title
                        #        slug
                        #    }
                        #}
                        tags {
                            title
                            slug
                            target {
                                ...linkTarget
                            }
                        }
                    }
                }
                allContentfulWodPageTemplate {
                    nodes {
                        seoTitle
                        seoDescription {
                            seoDescription
                        }
                        pageTitle
                        slug
                        createdAt
                        displayDate:createdAt(formatString: "DD MMM YYYY")
                    }
                }
            }
        `
    )

    let allPages = []
    Object.values(data).map(({ nodes }) => {
        nodes.map((element) => {
            allPages.push(element)
        })
    })
    return allPages
}
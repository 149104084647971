// extracted by mini-css-extract-plugin
export var col_1 = "footer-module--col_1--aec7a";
export var col_2 = "footer-module--col_2--d60ed";
export var col_3 = "footer-module--col_3--fd533";
export var container = "footer-module--container--f3aab";
export var container1 = "footer-module--container1--d57cf";
export var content = "footer-module--content--be032";
export var copyright = "footer-module--copyright--a5e45";
export var digitupLogo = "footer-module--digitupLogo--8cbc5";
export var digitupLogoContainer = "footer-module--digitupLogoContainer--c3146";
export var footerBar = "footer-module--footer-bar--c2671";
export var footerContainer = "footer-module--footer-container--6a882";
export var footerGradient = "footer-module--footer-gradient--71bdc";
export var footerLinkSingle = "footer-module--footerLinkSingle--36508";
export var footerLinks = "footer-module--footerLinks--f0f83";
export var footerLogo = "footer-module--footerLogo--af05e";
export var mainContainer = "footer-module--mainContainer--600ae";
export var policy = "footer-module--policy--12c03";
export var privacy = "footer-module--privacy--a9bac";
export var rights = "footer-module--rights--3885b";
export var socialContainer = "footer-module--socialContainer--425c8";
export var socialIcons = "footer-module--socialIcons--2c6ef";
export var terms = "footer-module--terms--cfa34";
export var topImage = "footer-module--topImage--28529";
import React from 'react'
import firebase from 'gatsby-plugin-firebase'

export const useCheckUser = () => {
    async function checkUser(userEmail: string) {
        try {
            const methods = await firebase
                .auth()
                .fetchSignInMethodsForEmail(userEmail)
            return methods.length > 0
        } catch (error) {
            console.error(error);

        }

    }

    return checkUser
}
import React, {useContext, useState} from 'react'
import * as styles from './profileDetailPopup.module.scss'
import { useSetUserDocument } from '../../hooks'
import { AuthContext } from '../../context/auth'
import { navigate } from 'gatsby'

const ProfileDetailPopup = ({setVisible}) => {

    let initialData = {
        firstName: '',
        lastName: '',
        companyName: ''
    }

    let [data, setData] = useState(initialData)
    let {firstName, lastName, companyName} = data
    const { user } = useContext(AuthContext)
    const setUserDocument = useSetUserDocument()

    const handleChange = (e) =>{
        const {name, value} = e.target
        setData(() => ({...data, [name]: value}))
    }

    const updateUser = async() =>{
        await setUserDocument(user, {
            firstName: firstName,
            lastName: lastName,
            companyName: companyName,
        })
        setVisible(false)
        navigate('/')
    }

    const submitHandler = (e) =>{
        e.preventDefault()
        // (data?.companyName == '' ) ? alert('plese enter value') : ''
        data.companyName === '' || data.companyName === 'Select...' ? alert("Enter Company Name"): updateUser()
    }

  return (
    <div >
        <div className={styles.overlay}></div>
        <div className={styles.popup}>
        {/* <div className={styles.close}> X </div> */}
        <p className={styles.text}>Please Enter following details to proceed further!</p>
            <div className={styles.section}> 
            <form onSubmit={submitHandler}>
                <div className={styles.input}>
                    <label> First Name*</label>
                    <input type = 'text' name = 'firstName' value = {firstName} required onChange={handleChange}/>
                </div>
                <div className={styles.input}>
                    <label> Last Name*</label>
                    <input type = 'text'  name = 'lastName' value = {lastName} required onChange={handleChange}/>
                </div>
                <div className={styles.input}>
                    <label> Company Name*</label>
                    {/* <input type = 'text' placeholder='company name'/> */}
                    <select name = 'companyName' value = {companyName} required onChange={handleChange}>
                        <option>Select...</option>
                        <option> Lipton</option>
                        <option> Maille</option>
                        <option> DigitUp</option>
                        <option> Recipedia</option>
                    </select>
                </div>
                <div>
                    <button type='submit' className={styles.btn}> Submit</button>
                </div>
            </form>
            <p className={styles.para}> All * fields are required to fill.</p>
            </div>
        </div>
    </div>
  )
}

export default ProfileDetailPopup
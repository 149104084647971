const USER_INFO = "user_info"
const USER_DETAILS = "user_details"
const SILKTIDE_REPORT_ALL = "silktide_report_all"
const SILKTIDE_REPORT_SINGLE = "silktide_report_single"
const HEADER_TOOLTIP = "header_tooltip"

export const LocalStorageKeys = {
  USER_INFO,
  USER_DETAILS,
  SILKTIDE_REPORT_ALL,
  SILKTIDE_REPORT_SINGLE,
  DARK_MODE: "dark_mode",
}

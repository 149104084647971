import React, { useContext } from "react";
import { ProfileContext } from "../../context/profile_data";
import { LocalStorage, LocalStorageKeys } from "../../utils";
import firebase from 'gatsby-plugin-firebase'

export function useSetUserDocument() {
    // const firestore = firebase.firestore()
    const { setProfile } = useContext(ProfileContext)

    async function setUserDocument(user, data) {
        if (!user || !data) return
        // console.log('data', data);
        
        const userRef = firebase.firestore().doc(`users/${user.uid}`)
        const snapShot = await userRef.get()
        try {
            userRef.set(data)
            LocalStorage.set(LocalStorageKeys.USER_INFO, JSON.stringify(data))

            setProfile(data)
        } catch (error) {
            console.error(error);
        }

    }

    return setUserDocument;
}


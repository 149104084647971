import React, { useState, useContext } from 'react'
import { AuthContext } from '../../context/auth'
import { ZendeskContext } from '../../context/context'
import { useSetRequesterId } from '../profile/useSetRequesterId'
export interface IsState {
    response?: any,
    loading: Boolean,
    error?: String
}

export interface formData {
    ticket: {
        subject: string,
        comment: {
            html_body: string,
            uploads?: any
        },
        // requester: {
        //     name: string,
        //     email: string
        // }
    }
    // file: any
}


export function useZendeskForm() {

    const { id, setId, counter, setCounter } = useContext(ZendeskContext)
    const { user, setUser } = useContext(AuthContext)

    const [state, setState] = useState<IsState | null>({
        response: null,
        loading: false,
        error: null
    })

    const setUserId = useSetRequesterId()

    async function zendeskForm(data: formData) {
        setState({ response: null, loading: true, error: null })
        try {
            const output = await fetch('/.netlify/functions/zendesk-form', {
                method: 'POST',
                body: JSON.stringify(data)
            })
            const res = await output.json()

            if (res.requesterId && !id)
                setId(res.requesterId)

            if (!user.requester_id) {
                setUser({
                    ...user,
                    requester_Id: res.requesterId
                })
                setUserId(res.requesterId)
            }
            setCounter(counter + 1)
            setState({ response: res, loading: false, error: null })
        } catch (error) {
            setState({
                response: null,
                loading: false,
                error: error
            })
        }
    }
    return [zendeskForm, state];
}

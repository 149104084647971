import React, { useState } from "react";

export interface IsState {
    response?: any,
    loading: Boolean,
    error?: String
}

export interface formData {
    url: string
}

export function usePerformance() {
    const [state, setState] = useState<IsState | null>({
        response: null,
        loading: false,
        error: null
    })


    async function performanceTest(data: formData) {
        // console.log("data", data);

        setState({ response: null, loading: true, error: null })
        let a
        try {
            await fetch(`https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${data.url}&strategy=mobile&key=AIzaSyAxDErjDfdbd6aIF-EzxTDtWmlz9oUNHx4`, {
                method: 'GET',
                redirect: 'follow'
            })
                .then(response => response.text())
                .then(result => {
                    const finalResult = JSON.parse(result)
                    // const originLoadingExperience = finalResult.originLoadingExperience.metrics
                    // const originLoadingExperienceValue = {
                    //     'First Contentful Paint': originLoadingExperience['FIRST_CONTENTFUL_PAINT_MS'],
                    //     'Speed Index': originLoadingExperience,
                    //     'Time To Interactive': originLoadingExperience,
                    //     'First Meaningful Paint': originLoadingExperience,
                    //     'Largest Contentful Paint': originLoadingExperience,
                    //     'Total Blocking Time': originLoadingExperience,
                    // }
                    // console.log("finalResult",finalResult);

                    const lighthouse = finalResult.lighthouseResult;
                    const performance = lighthouse.categories.performance.score
                    const lighthouseOthers = {
                        'First Contentful Paint': (lighthouse.audits['first-contentful-paint'].numericValue),
                        'Speed Index': (lighthouse.audits['speed-index'].numericValue),
                        'Time To Interactive': (lighthouse.audits['interactive'].numericValue),
                        'First Meaningful Paint': (lighthouse.audits['first-meaningful-paint'].numericValue),
                        'Largest Contentful Paint': (lighthouse.audits['largest-contentful-paint'].numericValue),
                        'Total Blocking Time': (lighthouse.audits['total-blocking-time'].numericValue),
                    };

                    const lighthouseMetrics = {
                        'First Contentful Paint': lighthouse.audits['first-contentful-paint'].displayValue,
                        'Speed Index': lighthouse.audits['speed-index'].displayValue,
                        'Time To Interactive': lighthouse.audits['interactive'].displayValue,
                        'First Meaningful Paint': lighthouse.audits['first-meaningful-paint'].displayValue,
                        'Largest Contentful Paint': lighthouse.audits['largest-contentful-paint'].displayValue,
                        'Total Blocking Time': lighthouse.audits['total-blocking-time'].displayValue
                    };


                    var dateObj = new Date();
                    var month = dateObj.getUTCMonth() + 1; //months from 1-12
                    var day = dateObj.getUTCDate();
                    var year = dateObj.getUTCFullYear();

                    let newdate = year + "/" + month + "/" + day;
                    // console.log("qwe", {
                    //     date: newdate,
                    //     metrics: lighthouseOthers,
                    //     displayedValues: lighthouseMetrics
                    // });

                    setState(
                        {
                            response: {
                                date: newdate,
                                metrics: lighthouseOthers,
                                displayedValues: lighthouseMetrics
                            },
                            loading: false,
                            error: null
                        }
                    )

                    a = {
                        metrics: lighthouseOthers,
                        displayedValues: lighthouseMetrics,
                        performance: performance,
                        loading: false,
                        error: null
                    }
                })
        } catch (error) {
            console.error(error);
        }
        return a
    }

    return [performanceTest, state]
}
import React, { useState } from "react";
import { Buffer } from 'buffer'
export interface IsState {
    response?: any,
    loading: Boolean,
    error?: String
}

export interface formData {
    file: any
}

export function useZendeskFile() {
    const [state, setState] = useState<IsState | null>({
        response: null,
        loading: false,
        error: null
    })

    const getToken = async (file) => {
        console.log(file, file.name);

        return await fetch(`https://digitupsolutionspvtltdhelp.zendesk.com/api/v2/uploads.json?filename=${file.name}`, {
            method: 'POST',
            headers: {
                'Content-Type': file.type,
                'Authorization': `Basic ${Buffer.from(`${process.env.ZENDESK_API}/token:${process.env.ZENDESK_TOKEN}`).toString('base64')}`
            },
            body: file,
            redirect: 'follow'
        })
            .then(response => response.text())
            .then(result => {
                let abc = JSON.parse(result).upload.token
                // setState({ response: abc, loading: false, error: null })
                return abc
            })
    }

    const zendeskFile = async (data: formData) => {
        setState({ response: null, loading: true, error: null })
        console.log(data);
        const tokenArray = []
        for (let index = 0; index < [...data.file].length; index++) {
            const element = data.file[index];
            console.log(element);
            const token = await getToken(element)
            // setFileTokens([...fileTokens, token])
            console.log("token", token);
            tokenArray.push(token)
        }

        console.log(tokenArray);
        setState({ response: tokenArray, loading: true, error: null })

        return tokenArray
        // try {
        // return await fetch(`https://digitupsolutionspvtltdhelp.zendesk.com/api/v2/uploads.json?filename=${data.name}`, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': data.type,
        //         'Authorization': `Basic ${Buffer.from(`${process.env.ZENDESK_API}/token:${process.env.ZENDESK_TOKEN}`).toString('base64')}`
        //     },
        //     body: data.file,
        //     redirect: 'follow'
        // })
        //     .then(response => response.text())
        //     .then(result => {
        //         let abc = JSON.parse(result).upload.token
        //         setState({ response: abc, loading: false, error: null })
        //         return abc
        //     })

        // const res = await output.json()
        // console.log(res);
        // console.log(output);

        // } catch (error) {
        //     setState({
        //         response: null,
        //         loading: false,
        //         error: error
        //     })
        // }
    }

    return [zendeskFile, state]

}
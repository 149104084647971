import React, { useState } from "react";

export interface IsState {
    response?: any,
    loading: Boolean,
    error?: String
}

export function useSignUpEmail() {
    const [state, setState] = useState<IsState | null>({
        response: null,
        loading: false,
        error: null
    })

    async function signUpEmail(data) {
        // console.log(data);
        
        setState({ response: null, loading: true, error: null })
        try {
            const output = await fetch('/.netlify/functions/sign-up-form', {
                method: 'POST',
                body: JSON.stringify(data)
            })
            const res = await output.json()
            setState({ response: res, loading: false, error: null })
        } catch (error) {
            // console.log(error);
            setState({
                response: null,
                loading: false,
                error: error
            })
        }
    }

    return [signUpEmail, state]
}
import React, { createContext, useState } from "react"
import { LocalStorage, LocalStorageKeys } from "../utils"

export const ZendeskContext = createContext({})

export const ZendeskProvider = ({ children }) => {
  const user_info = JSON.parse(LocalStorage.get(LocalStorageKeys.USER_INFO))
  const [id, setId] = useState(
    user_info?.requester_Id ? user_info.requester_Id : 0
  ) //User requester ID

  const [firstName, setFirstName] = useState(
    user_info?.firstName ? user_info.firstName : 0
  )

  const [counter, setCounter] = useState(0) //Used to run the useEffect again
  const [tickets, setTickets] = useState([]) //All user tickets will be placed here
  const [activeTickets, setActiveTickets] = useState([]) //All user active tickets will be placed here
  const [ticketLength, setTicketLength] = useState(0) //Active Ticket length will be placed here

  const [ticketObject, setTicketObject] = useState(0)

  return (
    <ZendeskContext.Provider
      value={{
        id,
        setId,
        counter,
        setCounter,
        tickets,
        setTickets,
        ticketLength,
        setTicketLength,
        activeTickets,
        setActiveTickets,
        ticketObject,
        setTicketObject,
        firstName,
        setFirstName
      }}
    >
      {children}
    </ZendeskContext.Provider>
  )
}

export const LayoutContext = createContext({})

export const LayoutProvider = ({ children }) => {
  const [quickView, setQuickView] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(false) // Initialize dark mode state
  const [visible, setVisible] = useState(false)
  return (
    <LayoutContext.Provider
      value={{
        quickView,
        setQuickView,
        isDarkMode,
        setIsDarkMode,
        visible,
        setVisible
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

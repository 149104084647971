import React, { useContext } from "react";
import firebase from 'gatsby-plugin-firebase'
import { usePerformance, useGetPerformance } from "../../hooks";

export function useSetPerformance() {

    const [performanceTest, { response, loading, error }] = usePerformance()
    const getPerformance = useGetPerformance()

    const setPerformance = async (brand: string, market: string, date: string, marketUrl: string) => {
        // let array = []
        let singleValue = {}

        const useRef = async () => {
            return await firebase
                .firestore()
                .collection(`performance`)
                .doc(brand)
                .collection(market)
                .doc(date)
        }

        try {
            const single = await useRef()

            const snapShot = await single.get()
            if (snapShot.exists) {
                // array = await getPerformance(brand, market)
                // console.log("array", array);
                singleValue = await snapShot.data()

            } else {
                await performanceTest({
                    url: marketUrl
                }).then((res) => {
                    single.set({
                        date: date,
                        displayedValues: res.displayedValues,
                        metrics: res.metrics,
                        performance: res.performance
                    })
                    singleValue = {
                        date: date,
                        displayedValues: res.displayedValues,
                        metrics: res.metrics,
                        performance: res.performance
                    }
                })

            }
        } catch (error) {
            console.error(error);
        }
        const arrayPerformance = await getPerformance(brand, market)
        let array = [["Date", "Performance"]]
        arrayPerformance
            && arrayPerformance.sort((a, b) => {
                return new Date(a.date) - new Date(b.date)
            }).map(({ date, performance }) => {
                array.push([
                    new Date(date.split('-').join('/')),
                    performance * 100
                ])
            })
        return {
            singleValue,
            array
        }
    }
    return setPerformance
}
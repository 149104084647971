import React, { useState, useEffect, useContext } from 'react'
import * as styles from './search-header.module.scss';

import { Link, navigate } from 'gatsby'

import { useSearchQuery, useLink } from '../../../hooks';
import Close from '../../../static/cross.svg'
import DarkthemeClose from '../../../static/dark-cross.svg'
import { LayoutContext } from '../../../context/context';


const SearchHeader = ({
    open,
    value,
    setValue,
    setOpen
}) => {
    const pages = useSearchQuery()
    // const [searchValue, setSearchValue] = useState(value)
    const [filteredArray, setFilteredArray] = useState([])
    const [fullArray, setFullArray] = useState([])
    const { isDarkMode } = useContext(LayoutContext) as { isDarkMode: boolean };


    useEffect(() => {
        const fullArray = pages
            .filter(({ seoTitle, seoDescription, pageTitle }) => seoTitle.toLowerCase().includes(value) || seoDescription?.seoDescription.toLowerCase().includes(value) || pageTitle.toLowerCase().includes(value))
        setFullArray(fullArray)
        const array = fullArray
            .filter((page, index) => index < 4)
        setFilteredArray(array)
    }, [value])

    return (
        <div className={`${styles.container} dark-color`}>
            <div className={styles.contentContainer} >
                <div className={styles.inputFieldDiv}>
                    <input
                        type="text"
                        value={value}
                        onChange={e => {
                            setValue(e.target.value.toLowerCase())
                            !e.target.value && setOpen(false)
                        }}
                        className={`heading heading-1 dark-color dark-theme-white-font`}
                        placeholder="Please enter..."
                        onKeyDown={e => {
                            if (e.key === "Enter") navigate(`/search?search_text=${value}`)
                        }}
                    />
                    {isDarkMode ? (
                        <>
                            <DarkthemeClose
                                className={styles.close}
                                onClick={e => {
                                    setOpen(false)
                                    setValue('')
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <Close
                                className={styles.close}
                                onClick={e => {
                                    setOpen(false)
                                    setValue('')
                                }}
                            />
                        </>
                    )}
                </div>
                <div className={styles.searchResults}>
                    <p className='heading heading-6'>Latest Articles</p>
                    <div>
                        {
                            filteredArray.map(({ pageTitle, slug }, index) => (
                                <Link
                                    className={styles.searchLink}
                                    to={`/${useLink(slug)}`}
                                    key={index}
                                    onClick={e => setOpen(false)}
                                >{pageTitle}</Link>
                            ))
                        }
                    </div>
                    <div className={styles.viewMore}>
                        {
                            fullArray.length > 4
                                ? <span className='dark-theme-white-font'
                                    onClick={e => navigate(`/search?search_text=${value}`)}
                                >View More</span>
                                : null
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default SearchHeader
import React, { useContext } from 'react'
import * as styles from "./profile-modal.module.scss"

import { Link } from 'gatsby'
// import { AuthContext } from '../../../../context/auth'
import firebase from "gatsby-plugin-firebase"
import { ProfileContext } from '../../../../context/profile_data'
import { ZendeskContext, LayoutContext } from '../../../../context/context'

const ProfileModal = ({ setOpen }) => {
    // const { user } = useContext(AuthContext)
    const { setProfile } = useContext(ProfileContext)
    const { setId } = useContext(ZendeskContext)
    const { isDarkMode } = useContext(LayoutContext) as { isDarkMode: boolean };
    const logout = async () => {
        await firebase
            .auth()
            .signOut()

        localStorage.clear()
        setProfile(null)
        setId(0)
    }

    return (
        <div className={`${styles.container} dark-color`}>
            <div className={styles.svgIcon}>
                {
                    isDarkMode ? (
                        <svg className={`styles.svg`} width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <filter id="shadow" x="0" y="0" width="100%" height="100%">
                                    <feDropShadow dx="0" dy="1" stdDeviation="1" floodColor="#d9d7d7" floodOpacity="0.2" />
                                </filter>
                            </defs>
                            <path filter="url(#shadow)" d="M9.40193 1.5C10.5566 -0.500003 13.4434 -0.5 14.5981 1.5L23.2583 16.5C24.413 18.5 22.9697 21 20.6603 21H3.33974C1.03034 21 -0.413031 18.5 0.74167 16.5L9.40193 1.5Z" fill="black" />
                        </svg>
                    ) :
                        <svg className={`styles.svg`} width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <filter id="shadow" x="0" y="0" width="100%" height="100%">
                                    <feDropShadow dx="0" dy="1" stdDeviation="1" floodColor="#d9d7d7" floodOpacity="0.2" />
                                </filter>
                            </defs>
                            <path filter="url(#shadow)" d="M9.40193 1.5C10.5566 -0.500003 13.4434 -0.5 14.5981 1.5L23.2583 16.5C24.413 18.5 22.9697 21 20.6603 21H3.33974C1.03034 21 -0.413031 18.5 0.74167 16.5L9.40193 1.5Z" fill="white" />
                        </svg>
                }

            </div>
            <div className={styles.containerContent}>
                <div className={`styles.profile`}>
                    <Link
                        className={styles.contentSeparateLink}
                        to={`/profile`}
                        onClick={() => {
                            setOpen(false)
                        }}
                    >
                        {/* {item.readMoreLink?.title} */}
                        Profile
                    </Link>
                </div>
                <div className={`styles.logout`}>
                    <span
                        className={styles.contentSeparateLink}
                        onClick={() => {
                            logout()
                            setOpen(false)
                        }}
                    >
                        {/* {item.readMoreLink?.title} */}
                        SignOut
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ProfileModal
import React from 'react'
import * as styles from './rich-text-link.module.scss'

import { Link } from 'gatsby'
import { useLink } from '../../../hooks'
import ButtonLink from '../../shared/ButtonLink/ButtonLink'

const RichTextLink = ({
    slug,
    title
}) => {
    return (
        <div className={`rich-text-cta ${styles.link}`}>
            <ButtonLink
                title={title}
                slug={slug}
            />
        </div>
    )
}

export default RichTextLink
import React, { useState, useContext } from 'react'
import { ZendeskContext } from '../../context/context'
export interface IsState {
    response?: any,
    loading: Boolean,
    error?: String
}

export interface formData {
    id: number
}


export function useZendeskTickets() {

    const [state, setState] = useState<IsState | null>({
        response: null,
        loading: false,
        error: null
    })

    const { setTicketObject } = useContext(ZendeskContext)

    async function zendeskTicket(data: formData) {
        setState({ response: null, loading: true, error: null })
        try {
            const output = await fetch('/.netlify/functions/zendesk-tickets', {
                method: 'POST',
                body: JSON.stringify(data)
            })
            const res = await output.json()
            setTicketObject(res.tickets)
            console.log("tickets", res);

            setState({ response: res, loading: false, error: null })
        } catch (error) {
            setState({
                response: null,
                loading: false,
                error: error
            })
        }
    }

    return [zendeskTicket, state];
}

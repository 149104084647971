// extracted by mini-css-extract-plugin
export var buttonContainer = "zendesk-quick-panel-module--button-container--53ac3";
export var checkbox = "zendesk-quick-panel-module--checkbox--a9503";
export var container = "zendesk-quick-panel-module--container--cd28b";
export var dateTimeContainer = "zendesk-quick-panel-module--date-time-container--ad2f5";
export var emptyAnimation = "zendesk-quick-panel-module--empty-animation--7dfe9";
export var overlay = "zendesk-quick-panel-module--overlay--f5acf";
export var quickViewGradient = "zendesk-quick-panel-module--quick-view-gradient--6096e";
export var ticketArticle = "zendesk-quick-panel-module--ticket-article--cbb15";
export var ticketSubject = "zendesk-quick-panel-module--ticket-subject--83dc2";
export var ticketTitle = "zendesk-quick-panel-module--ticket-title--592ae";
export var ticketsContainer = "zendesk-quick-panel-module--tickets-container--883db";
export var wrapper = "zendesk-quick-panel-module--wrapper--e1b9d";
export var zendeskIcon = "zendesk-quick-panel-module--zendesk-icon--76053";
import firebase from "gatsby-plugin-firebase";

export const useGetUserDetails = () =>{

    const getUserDetails = async () =>{
        const snapshot = await firebase
            .firestore()
            .collection(`users`)
            .get()
            
            const result =  snapshot.docs.map((doc) => {
                const singleDoc = doc.data()
                return singleDoc
            })
            return result
    }
    return getUserDetails
}
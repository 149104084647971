import React, { useRef, useEffect, useState, useContext } from 'react'
import * as styles from './zendesk-quick-panel.module.scss'
import { LayoutContext, ZendeskContext } from '../../../context/context'
import ButtonLink from '../../shared/ButtonLink/ButtonLink'
import SecondaryButtonLink from '../../shared/SecondaryButtonLink/SecondaryButtonLink'

import ZendeskIcon from '../../../images/ZendeskIcons/zendesk_logo.svg'
import DarkZendeskIcon from '../../../images/ZendeskIcons/dark_zendesk_logo.svg'

import { useConvertDate } from '../../../hooks'

import BottomGradient from '../../../images/gradientImages/quick-view-panel.png'

import Lottie from 'react-lottie';
import loader from '../../../static/lottie/no-notification-2.json';
import darkloader from '../../../static/lottie/no-notification-dark.json';

import { useLocation } from '@reach/router'



const loaderAnimation = JSON.parse(JSON.stringify(loader))

const darkloaderAnimation = JSON.parse(JSON.stringify(darkloader))

import Joyride, { CallBackProps, STATUS, Step, StoreHelpers } from 'react-joyride';



const ZendeskQuickPanel = () => {
    const location = useLocation()

    const [runJoyride, setRunJoyride] = useState(false)
    const { isDarkMode } = useContext(LayoutContext) as { isDarkMode: boolean };
    const quickViewRef = useRef(null)
    const [height, setHeight] = useState('47px')
    const {
        quickView,
        setQuickView
    } = useContext(LayoutContext)

    const {
        activeTickets
    } = useContext(ZendeskContext)

    useEffect(() => {
        quickViewRef.current.checked = quickView
        if (quickView)
            setRunJoyride(true)
    }, [quickView])

    const firstLetterCapital = (string: string) => {
        return `${string.charAt(0).toUpperCase() + string.substr(1, string.length)}`
    }

    useEffect(() => {
        if (document.querySelector('#header-div')?.offsetHeight)
            setHeight(`${document.querySelector('#header-div')?.offsetHeight}px`)
    }, [])

    useEffect(() => {
        quickViewRef.current.checked = false
        setQuickView(false)
    }, [location])

    const convertTime = (date) => {
        const time = new Date(date)
        return time.toLocaleString([], { hour: 'numeric', minute: 'numeric', hour12: true })
    }

    const closePanel = () => {
        quickViewRef.current.checked = false
        setQuickView(false)
    }

    const lottieConfig = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    const lottieConfigDark = {
        loop: true,
        autoplay: true,
        animationData: darkloaderAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }


    const openCloseView = () => {
        // console.log("quickView", quickView);

        setQuickView(!quickView)
    }

    const [joyrideSteps, setJoyrideSteps] = useState([
        // {
        //     content: <h2>To view all the tickets</h2>,
        //     placement: 'left',
        //     target: '#quick-panel-view-all',
        // },
        // {
        //     content: <h2>To create a new request</h2>,
        //     placement: 'left',
        //     target: '#quick-panel-raise-a-request',
        //     showBackButton: true
        // },
        // {
        //     content: <h2>Tickets Overview</h2>,
        //     placement: 'left',
        //     target: `#quick-panel-main-container`,
        //     showBackButton: true,
        // }
    ])

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status, type } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            // this.setState({ run: false });
            setRunJoyride(false)
            // LocalStorage.set(LocalStorageKeys.HEADER_TOOLTIP, "true")
        }

        console.groupCollapsed(type);
        // console.log(data);
        console.groupEnd();
    };

    return (
        <div>
            <Joyride
                callback={handleJoyrideCallback}
                continuous={true}
                run={runJoyride}
                scrollToFirstStep={true}
                showProgress={false}
                // showSkipButton={false}
                steps={joyrideSteps}
                // hideBackButton={false}
                styles={{
                    options: {
                        width: '300px',
                        zIndex: 10000,
                        primaryColor: "var(--global-color, #2f27c5)"
                    },
                    buttonNext: {
                        padding: '8px 16px',
                        fontFamily: 'proxima-nova',
                        border: '1px solid #2F27C5',
                        borderRadius: '5px'
                    },
                    buttonBack: {
                        padding: '8px 16px',
                        border: '1px solid #2F27C5',
                        borderRadius: '5px'
                    }
                }}
            />
            <input type="checkbox" ref={quickViewRef} name="" id="quick-panel" className={styles.checkbox} hidden />
            <div className={styles.wrapper} style={{ "--top-height": height }}>
                <div className={styles.overlay} onClick={e => closePanel()}>&nbsp;</div>
                <div className={`${styles.container} dark-theme-lottie`} >
                    <div className={styles.buttonContainer}>
                        <ButtonLink title='View all' id='quick-panel-view-all' slug='ticket' />
                        <SecondaryButtonLink title='Raise a Request' id='quick-panel-raise-a-request' slug='zendesk' />
                    </div>
                    <div id='quick-panel-main-container'>


                        {
                            activeTickets && activeTickets.length > 0
                                ? <div className={styles.ticketsContainer}>
                                    {activeTickets.map((ticket, index) => {
                                        return (
                                            <div className={styles.ticketArticle} key={index}>
                                                <div className={`${styles.ticketTitle} dark-theme-white-font `}>
                                                    {isDarkMode ? (
                                                        <DarkZendeskIcon className={styles.zendeskIcon} />
                                                    ) : (
                                                        <ZendeskIcon className={styles.zendeskIcon} />
                                                    )
                                                    }

                                                    <p className='dark-theme-white-font'>Ticket no #{ticket.id}</p>
                                                </div>
                                                <p className={`${styles.ticketSubject} dark-theme-white-font `}>{firstLetterCapital(ticket.subject)}</p>
                                                <div className={styles.dateTimeContainer}>
                                                    <span className='dark-theme-white-font '>Created At</span>
                                                    <span className='dark-theme-white-font '>|</span>
                                                    <span className='dark-theme-white-font '>{useConvertDate(ticket.created_at)}</span>
                                                    <span className='dark-theme-white-font '>|</span>
                                                    <span className='dark-theme-white-font '>{convertTime(ticket.created_at)}</span>
                                                </div>
                                                {
                                                    !(useConvertDate(ticket.created_at) === useConvertDate(ticket.updated_at) && convertTime(ticket.created_at) === convertTime(ticket.updated_at)) &&
                                                    <div className={styles.dateTimeContainer}>
                                                        <span className='dark-theme-white-font '>Updated At</span>
                                                        <span className='dark-theme-white-font '>|</span>
                                                        <span className='dark-theme-white-font '>{useConvertDate(ticket.updated_at)}</span>
                                                        <span className='dark-theme-white-font '>|</span>
                                                        <span className='dark-theme-white-font '> {convertTime(ticket.updated_at)}</span>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                                : <div id="empty-panel-animation" className={styles.emptyAnimation}>
                                    {isDarkMode ? (
                                        <Lottie
                                            options={lottieConfigDark}
                                            height={175}
                                            width={175}
                                        />
                                    ) :
                                        <Lottie
                                            options={lottieConfig}
                                            height={175}
                                            width={175}
                                        />
                                    }
                                </div>
                        }
                    </div>
                    <img src={BottomGradient} alt="bottom gradient" className={`gradient ${styles.quickViewGradient}`} />
                </div>
            </div>
        </div>
    )
}



export default ZendeskQuickPanel
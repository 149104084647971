import React from "react";

export const useConvertDate = (date) => {
    const monthNames = ["January", "February", "March", "April",
        "May", "June", "July", "August",
        "September", "October", "November", "December"];

    const day = new Date(date).getDate();

    const monthIndex = new Date(date).getMonth();
    const monthName = monthNames[monthIndex];

    const year = new Date(date).getFullYear();

    return `${day} ${monthName}, ${year}`;
}
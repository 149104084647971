import React, { useContext } from "react";
import { ProfileContext } from "../../context/profile_data";
import { LocalStorage, LocalStorageKeys } from "../../utils";
import firebase from 'gatsby-plugin-firebase'
import { AuthContext } from "../../context/auth";

export function useSetRequesterId() {
    // const firestore = firebase.firestore()
    const { setProfile } = useContext(ProfileContext)
    const { user } = useContext(AuthContext)

    async function setUserId(id) {

        if (!id) return

        const userRef = firebase.firestore().doc(`users/${user.uid}`)
        const snapShot = await userRef.get()
        console.log(snapShot);
        const data = snapShot.data()
        try {
            userRef.set({
                ...data,
                requester_Id: id
            })
            LocalStorage.set(LocalStorageKeys.USER_INFO, JSON.stringify({
                ...data,
                requester_Id: id
            }))

            setProfile({
                ...data,
                requester_Id: id
            })

        } catch (error) {
            console.error(error);
        }

    }

    return setUserId;
}


import React from 'react'

import { useLink } from '../../../hooks'

import { Link } from "gatsby"

const SecondaryButtonLink = ({
    title,
    slug,
    target,
    externalLink,
    extraClass,
    id
}: {
    title: string,
    slug?: string,
    target?: any,
    externalLink?: string,
    extraClass?: any,
    id?: string
}) => {
    if (slug)
        return (
            <Link to={`/${useLink(slug)}`} className={`secondary-button dark-color ${extraClass}`}>
                <span> {title}</span>
            </Link>
        )
    else if (target && target.slug)
        return (
            <Link id={id} to={`/${useLink(target.slug)}`} className={`secondary-button ${extraClass}`}>
                <span>{title}</span>
            </Link>
        )
    else if (externalLink)
        return (
            <a id={id} href={externalLink} target="_blank" className={`secondary-button ${extraClass}`}>
                <span>{title}</span>
            </a>
        )
    else
        return (
            <a id={id} className={`secondary-button ${extraClass}`}>
                <span>{title}</span>
            </a>
        )
}

export default SecondaryButtonLink
import React, { createContext, useState, useEffect } from "react";
import firebase from "gatsby-plugin-firebase";
import { LocalStorage } from "../utils";
export const ProfileContext = createContext({})

const ProfileProvider = ({ children }) => {

    const initialData = LocalStorage.getInitialInfo()

    const [profile, setProfile] = useState(initialData ? initialData : {
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        rollType: '',
        jobTitle: '',
        description: '',
    })

    return (
        <ProfileContext.Provider value={{ profile, setProfile }}>
            {children}
        </ProfileContext.Provider>
    )
}

export default ProfileProvider;
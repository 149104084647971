import { LocalStorageKeys } from "./keys"

function set(key: string, value: string) {
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    window.localStorage.setItem(key, value)
  }
}

function remove(key: string) {
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    window.localStorage.removeItem(key)
  }
}

function get(key: string) {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) {
    return null
  }

  try {
    const item = window.localStorage.getItem(key)
    return item
  } catch {
    return null
  }
}

function getInitialInfo() {
  const userData = get(LocalStorageKeys.USER_INFO)
  if (userData == null) return null

  try {
    const existingData = JSON.parse(userData)
    if (userData) return existingData
    return existingData
  } catch (error) {
    return null
  }
}

// Add these functions for dark mode handling
function setDarkMode(isDarkMode: boolean) {
  set(LocalStorageKeys.DARK_MODE, String(isDarkMode))
}

function getDarkMode() {
  const darkModeValue = get(LocalStorageKeys.DARK_MODE)
  return darkModeValue === "true"
}

export const LocalStorage = {
  get,
  remove,
  set,
  getInitialInfo,
  setDarkMode,
  getDarkMode,
}

import React, { createContext, useState, useEffect } from "react";
import firebase from "gatsby-plugin-firebase";
import { LocalStorage, LocalStorageKeys } from "../utils";
import { useLocation } from "@reach/router"
import { navigate } from "gatsby";

export const AuthContext = createContext({})

const AuthProvider = ({ children }) => {
    const info = LocalStorage.get(LocalStorageKeys.USER_DETAILS)
    const [user, setUser] = useState(info ? JSON.parse(info) : null)
    const location = useLocation()
    const [loading, setLoading] = useState(false)


    useEffect(async () => {
        setLoading(true)
        // firebase.auth().onAuthStateChanged(user => setUser(user))
        const queryString = location.search.substring(1)

        // Parse the query string into an object
        const queryParams = new URLSearchParams(queryString)

        // Get the value of the 'data' query parameter
        const encodedData = queryParams.get("user")
        console.log('encoded user params',encodedData)

        if (encodedData) {
            // Decode the URL-encoded data
            const decodedData = decodeURIComponent(encodedData)
            // console.log('unparsed data',decodedData)

            // Parse the JSON string into a JavaScript object
            const userparam = JSON.parse(decodedData)
            console.log('userparams',userparam)
            const { user } = await firebase
                .auth()
                .signInWithEmailAndPassword(userparam.email, userparam.password)

            setUser(userparam)
            LocalStorage.set(LocalStorageKeys.USER_DETAILS, JSON.stringify(userparam))
            // navigate('/')

            //  const jsonString = JSON.stringify(userparam, null, 2); // Use null and 2 for formatting
            // console.log('User parameters (stringified):', jsonString);
        }
    }, [])

    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => setUser(user))
    }, [])

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;
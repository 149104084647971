import React, { useState } from "react";
import firebase from "gatsby-plugin-firebase";
import { LocalStorage, LocalStorageKeys } from "../../utils";
export interface IsState {
    response?: any,
    loading: Boolean,
    error?: String | null
}

export const useGetSilktideReport = () => {
    const currentData = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`
    const [state, setState] = useState<IsState | null>({
        response: null,
        loading: false,
        error: null
    })

    const getAllReports = async (marketUrl) => {
        console.log("marketUrl", marketUrl);

        const database = await firebase
            .firestore()
            .collection('silktide').get()
        const result = await database.docs.map((doc) => {
            const singleDoc = doc.data()
            return Object.values(singleDoc)
        })
        const marketResults = result.map((docs) => {
            const relevantResult = docs.find(({ homeUrl }) => homeUrl === marketUrl || homeUrl.includes(marketUrl) || marketUrl.includes(homeUrl))
            return {
                date: relevantResult.date,
                stats: relevantResult.stats
            }
        })
        console.log("marketResults", marketResults);

        let array = [["Date", "Performance"]]

        marketResults
            .sort((a, b) => {
                return new Date(a.date) - new Date(b.date)
            }).map(({ date, stats }) => {
                if (new Date(date.split('-').join('/')) != "Invalid Date")
                    array.push([
                        new Date(date.split('-').join('/')),
                        parseFloat(stats['Overall score'])
                    ])
            })

        LocalStorage.set(LocalStorageKeys.SILKTIDE_REPORT_ALL, JSON.stringify({
            date: currentData,
            array
        }))
        return array
    }


    const sendAndGetDataFromFirebase = async () => {
        const userRef = async () => {
            return await firebase
                .firestore()
                .collection('silktide')
                .doc(currentData)
        }
        let silktideReport
        try {
            const singleDoc = await userRef()
            const snapShot = await singleDoc.get()
            if (snapShot.exists) {
                let report = await snapShot.data()
                silktideReport = Object.values(report)
            } else {
                var requestOptions = {
                    method: 'GET',
                    redirect: 'follow'
                };

                const output = await fetch("https://api.eu.silktide.com/v1/websites/stats?apiKey=eu:5105d252-ee00-4b18-b456-2ee17d19ecbf", requestOptions)
                const { websites } = await output.json()

                silktideReport = Object.values(websites)?.map(({ homeUrl, name, stats }) => {
                    return {
                        homeUrl,
                        name,
                        date: currentData,
                        stats: {
                            "Overall score": stats["overall.score"].value.toFixed(2),
                            "Content module": stats["content.score"].value.toFixed(2),
                            "Marketing module": stats["marketing.score"].value.toFixed(2),
                            "Accessibility": stats["wcag-21.score"].value.toFixed(2),
                            "User Experience": stats["ux.score"].value.toFixed(2),
                        }
                    }
                })
                singleDoc.set({ ...silktideReport })
            }
        } catch (error) {
            setState({
                response: null,
                loading: false,
                error: error
            })
        }
        LocalStorage.set(LocalStorageKeys.SILKTIDE_REPORT_SINGLE, JSON.stringify({
            date: currentData,
            report: silktideReport
        }))

        return silktideReport
    }

    const getSilktideReport = async (marketUrl) => {
        // const a = await fetch('/.netlify/functions/zendesk-form')
        // console.log("asdadsa", marketUrl);

        setState({
            response: null,
            loading: true,
            error: null
        })

        try {
            let silktideReport
            let array
            // const localStorageValues_Single = JSON.parse(LocalStorage.get(LocalStorageKeys.SILKTIDE_REPORT_SINGLE))
            // const differenceBetweenDates_Single = localStorageValues_Single?.date ? Math.floor((Date.parse(new Date()) - Date.parse(localStorageValues_Single.date)) / 86400000) : null
            // // console.log("localStorageValues_Single", localStorageValues_Single);
            // // console.log("differenceBetweenDates_Single", differenceBetweenDates_Single);

            // if (!localStorageValues_Single || (differenceBetweenDates_Single && differenceBetweenDates_Single <= 1))
            silktideReport = await sendAndGetDataFromFirebase()
            // else
            //     silktideReport = localStorageValues_Single?.report


            // const localStorageValues_All = JSON.parse(LocalStorage.get(LocalStorageKeys.SILKTIDE_REPORT_ALL))
            // const differenceBetweenDates_All = localStorageValues_All?.date ? Math.floor((Date.parse(new Date()) - Date.parse(localStorageValues_All.date)) / 86400000) : null

            // if (!localStorageValues_All || (differenceBetweenDates_All && differenceBetweenDates_All <= 1))
            array = await getAllReports(marketUrl)
            // else
            //     array = localStorageValues_All?.array
            console.log("silktideReport", silktideReport);

            const singleReport = silktideReport?.find(({ homeUrl }) => homeUrl === marketUrl || homeUrl.includes(marketUrl) || marketUrl.includes(homeUrl))

            setState({
                response: {
                    singleReport,
                    array: array
                },
                loading: false,
                error: null
            })
        } catch (error) {
            console.error(error);
            setState({
                response: null,
                loading: false,
                error: error
            })
        }
    }

    return [getSilktideReport, state]
}
import React from 'react'

import { useLink } from '../../../hooks'

import { Link } from "gatsby"

const ButtonLink = ({
    title,
    slug,
    target,
    externalLink,
    extraClass,
    id,
    submitButton = false,
    clickHandler
}: {
    title: string,
    slug?: string,
    target?: any,
    externalLink?: string,
    extraClass?: any,
    id?: string,
    submitButton?: boolean,
    clickHandler?: any
}) => {

    if (slug)
        return (
            <div className={`highlight`} id={id}>
                <Link to={`/${useLink(slug)}`} className={`button ${extraClass}`}>
                    {title}
                </Link>
            </div>
        )
    else if (target && target.slug)
        return (
            <div className={`highlight`} id={id}>
                <Link to={`/${useLink(target.slug)}`} className={`button ${extraClass}`}>
                    {title}
                </Link>
            </div>
        )
    else if (externalLink)
        return (
            <div className={`highlight`} id={id}>
                <a href={externalLink} target="_blank" className={`button ${extraClass}`}>
                    {title}
                </a>
            </div>
        )
    else if (submitButton) {
        return (
            <div className={`highlight`}>
                <button type="submit" className={`button ${extraClass}`}>
                    {title}
                </button>
            </div>
        )
    }
    else if (clickHandler) {
        return (
            <div className={`highlight`}>
                <button onClick={() => clickHandler()} className={`button ${extraClass}`}>
                    {title}
                </button>
            </div>
        )
    }
    else
        return (
            <div className={`highlight`}>
                <a className={`button ${extraClass}`}>
                    {title}
                </a>
            </div>
        )
}

export default ButtonLink